div#about {
    margin: 80px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

div#about .about {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
}

div#about .about .title p {
    font-size: 30px;
    font-weight: 700;
    line-height: 64px;
    color: var(--bg);
}

div#about .about .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 196px;
}

#about .about .title img.border_about_2 {
    transform: rotate(180deg);
}

div#about .about p.p_1 {
    font-size: 19px;
    font-weight: 400;
    line-height: 34px;
    color: #1E1E1E;
    width: 90%;
    max-width: 1025px;
}

div#about .about .about_images {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 1070px;
    gap: 10px;
}

div#about .about .about_images .left {
    flex: 739px;
}

div#about .about .about_images .right {
    flex: 305px;
}

div#about .about .youtube {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #ABD693;
}

div#about .about .youtube .iframe {
    width: 100%;
    min-height: 351px;
}

div#about .about .youtube .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 70px 100px;
    direction: rtl;
}

div#about .about .youtube .right p {
    font-size: 19px;
    font-weight: 400;
    line-height: 34px;
    text-align: justify;
    color: #636362;
}

div#about .about .youtube .right a.get {
    font-size: 19px;
    font-weight: 700;
    line-height: 34px;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    color: var(--bg);
}

@media (max-width: 1100px) {
    div#about .about .youtube .iframe {
        min-height: 298.35px;
    }

    div#about .about .youtube .right {
        padding: 59.5px 85px;
    }

    div#about .about .youtube .right p {
        font-size: 16.15px;
        line-height: 28.9px;
    }

    div#about .about .youtube .right a.get {
        font-size: 16.15px;
        line-height: 28.9px;
    }
}

@media (max-width: 900px) {
    div#about .about .youtube .iframe {
        min-height: 253.6325px;
    }

    div#about .about .youtube .right {
        padding: 50.575px 72.25px;
    }

    div#about .about .youtube .right p {
        font-size: 13.73275px;
        line-height: 24.565px;
    }

    div#about .about .youtube .right a.get {
        font-size: 13.73275px;
        line-height: 24.565px;
    }
}

@media (max-width: 750px) {
    div#about .about .youtube .iframe {
        min-height: 215.587625px;
    }

    div#about .about .youtube .right {
        padding: 42.98975px 61.4125px;
    }

    div#about .about .youtube .right p {
        font-size: 11.6728375px;
        line-height: 20.88025px;
    }

    div#about .about .youtube .right a.get {
        font-size: 11.6728375px;
        line-height: 20.88025px;
    }
}

@media (max-width: 600px) {
    div#about .about .youtube {
        grid-template-columns: 1fr;
    }

    div#about .about .youtube .iframe {
        min-height: 183.24948125px;
    }

    div#about .about .youtube .right {
        padding: 36.5412875px 52.200625px;
    }

    div#about .about .youtube .right p {
        font-size: 9.921911875px;
        line-height: 17.7482125px;
    }

    div#about .about .youtube .right a.get {
        font-size: 9.921911875px;
        line-height: 17.7482125px;
    }
}