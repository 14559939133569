:root {
  --bg: #7B498B;
  --cl: #f6ecdb;
  --ff: #fff;
  --bl: #111;
  --gg: #636362;
}

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: var(--cl);
}

::-webkit-scrollbar-thumb {
  background: var(--bg);
  border-radius: 20px;
}

img,
video {
  width: 100%;
}

body {
  font-family: "Cairo", sans-serif;
  overflow-x: hidden;
  max-width: 1280px;
  margin: auto;
}

p {
  direction: rtl;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}