div#contact {
    margin: 80px auto;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 938px;
    padding-top: 110px;
    padding-bottom: 75px;
    margin-top: 0;
    direction: rtl;
}

div#contact img.img_contact {
    position: absolute;
    inset: 0;
    z-index: -1;
    height: 100%;
    object-fit: cover;
}

div#contact .contact {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
}

div#contact .contact .title p {
    font-size: 45px;
    font-weight: 700;
    line-height: 64px;
    color: var(--bg);
}

div#contact .contact .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 255px;
}

div#contact .contact .title img {
    max-width: 196px;
}

#contact .contact .title img.border_contact_2 {
    transform: rotate(180deg);
}

form.form_contact {
    width: 90%;
    max-width: 850px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 50px;
}

form.form_contact .firstName_lastName {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

form.form_contact .firstName_lastName div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

form.form_contact .firstName_lastName div label {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--bg);
}

form.form_contact .firstName_lastName div:first-child label {
    color: var(--bg);
}

form.form_contact input {
    padding: 10px 5px;
    border-bottom: 1px solid var(--bg);
    width: 90%;
    background: transparent;
}

form.form_contact input::placeholder {
    color: var(--bg);
}

form.form_contact .select_subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--bg);
    gap: 15px;
}

form.form_contact .select_subject .select_subject_input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

form.form_contact .select_subject .select_subject_input div {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
    overflow: hidden;
    position: relative;
}

form.form_contact .select_subject .select_subject_input div label {
    cursor: pointer;
}

form.form_contact .message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--bg);
    gap: 15px;
}

form.form_contact .send_message {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

form.form_contact .send_message .send_message_img {
    display: flex;
}

form.form_contact .send_message .send_message_img img {
    width: 324.89px;
}

form.form_contact .send_message button.discover_team {
    width: 196px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
}

input[type="radio"] {
    position: absolute;
    top: -100%;
}

input[type="radio"]+span {
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    background: #878787;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 5px;
    flex-shrink: 0;
}

input[type="radio"]+span svg {
    display: none;
}

input[type="radio"]:checked+span {
    background: #6572EC;
}

input[type="radio"]:checked+span svg {
    display: block;
}


form.form_contact .submit_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

form.form_contact button {
    width: 196px;
    height: 50.22px;
    border-radius: 500px;
    color: var(--ff);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    background: #ABD693;
    cursor: pointer;
}

form.form_contact button:hover {
    opacity: 0.7;
}

@media (max-width: 750px) {
    form.form_contact .send_message .send_message_img img {
        width: 200px;
    }

    form.form_contact .send_message {
        flex-direction: column-reverse;
    }
}