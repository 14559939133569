div#header {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

div#header .img_header {
    animation: header_background 10s ease-in-out infinite alternate;
}

@keyframes header_background {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(5);
    }
}

div#header .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

div#header .header p.p_1 {
    font-size: 65px;
    font-weight: 700;
    line-height: 64px;
    color: var(--bg);
}

div#header .header p.p_1 span {
    display: block;
    font-size: 55px;
    font-weight: 400;
    line-height: 54px;
    margin-bottom: 25px;
}

div#header .header p.p_2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    width: 80%;
    max-width: 600px;
    color: var(--gg);
}

div#header .header .button {
    width: 196px;
    height: 62px;
    border-radius: 500px;
    background: #ABD693;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    color: var(--ff);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 1100px) {
    div#header .header p.p_1 {
        font-size: 55.25px;
        line-height: 54.4px;
    }

    div#header .header p.p_1 span {
        font-size: 46.75px;
        line-height: 45.9px;
    }

    div#header .header p.p_2 {
        font-size: 13.6px;
        line-height: 19.55px;
    }

    div#header .header .button {
        width: 166.6px;
        height: 52.7px;
        font-size: 13.6px;
        line-height: 19.55px;
    }
}

@media (max-width: 900px) {
    div#header .header p.p_1 {
        font-size: 46.96625px;
        line-height: 46.24px;
    }

    div#header .header p.p_1 span {
        font-size: 39.74375px;
        line-height: 39.015px;
    }

    div#header .header p.p_2 {
        font-size: 11.56px;
        line-height: 16.6175px;
    }

    div#header .header .button {
        width: 141.31px;
        height: 44.795px;
        font-size: 11.56px;
        line-height: 16.6175px;
    }
}

@media (max-width: 750px) {
    div#header .header p.p_1 {
        font-size: 39.9213125px;
        line-height: 39.304px;
    }

    div#header .header p.p_1 span {
        font-size: 33.7821875px;
        line-height: 32.86275px;
    }

    div#header .header p.p_2 {
        font-size: 9.826px;
        line-height: 14.124875px;
    }

    div#header .header .button {
        width: 120.1135px;
        height: 38.07575px;
        font-size: 9.826px;
        line-height: 14.124875px;
    }
}

@media (max-width: 600px) {
    div#header .header p.p_1 {
        font-size: 33.932115625px;
        line-height: 33.4084px;
    }

    div#header .header p.p_1 span {
        font-size: 28.715859375px;
        line-height: 27.9333375px;
    }

    div#header .header p.p_2 {
        font-size: 8.3521px;
        line-height: 12.00614375px;
    }

    div#header .header .button {
        width: 102.096475px;
        height: 32.3643875px;
        font-size: 8.3521px;
        line-height: 12.00614375px;
    }
}

@media (max-width: 430px) {
    div#header .header p.p_1 {
        font-size: 28.84229828125px;
        line-height: 28.39614px;
    }

    div#header .header p.p_1 span {
        font-size: 24.40848046875px;
        line-height: 23.743336875px;
    }

    div#header .header p.p_2 {
        font-size: 7.104285px;
        line-height: 10.2052221875px;
    }

    div#header .header .button {
        width: 86.78200375px;
        height: 27.509729375px;
        font-size: 7.104285px;
        line-height: 10.2052221875px;
    }
}

@media (min-width: 320px) and (max-width: 400px) {}