.copyrights {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
}

.copyrights a {
    color: var(--ff);
}