div#galley {
    margin: 80px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

div#galley .galley {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
}

div#galley .galley .title p {
    font-size: 45px;
    font-weight: 700;
    line-height: 64px;
    color: var(--bg);
}

div#galley .galley .title p.p_border_about_1 {
    font-size: 25px;
}

div#galley .galley .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 196px;
}

#galley .galley .title img.border_galley_2 {
    transform: rotate(180deg);
}

div#galley .galley p.p_1 {
    font-size: 19px;
    font-weight: 400;
    line-height: 34px;
    color: #1E1E1E;
    width: 90%;
    max-width: 1025px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div#galley .galley .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

div#galley .galley .slider .selected {
    width: 60%;
    height: 350px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
}

div#galley .galley .slider .selected img {
    object-fit: cover;
}

div#galley .galley .slider .list {
    display: flex;
    gap: 10px;
    justify-content: center;
}

div#galley .galley .slider .list img {
    width: 12%;
    border-radius: 20px;
    opacity: 0.5;
    cursor: pointer;
}

div#galley .galley .slider .list img:hover {
    transform: scale(1.02);
}

div#galley .galley .slider .list img.active {
    opacity: 1;
}

@media (max-width: 1100px) {
    div#galley .galley .slider {
        gap: 17px;
    }

    div#galley .galley .slider .selected {
        height: 297.5px;
        border-radius: 85px;
    }
}

@media (max-width: 900px) {
    div#galley .galley .slider {
        gap: 14px;
    }

    div#galley .galley .slider .selected {
        height: 252.875px;
        border-radius: 72.25px;
    }
}

@media (max-width: 750px) {
    div#galley .galley .slider {
        gap: 12px;
    }

    div#galley .galley .slider .selected {
        height: 215.94375px;
        border-radius: 61.4125px;
    }
}

@media (max-width: 600px) {
    div#galley .galley .slider {
        gap: 10px;
    }

    div#galley .galley .slider .selected {
        height: 183.5521875px;
        border-radius: 52.700625px;
    }
}