#footer {
    width: 100%;
    height: 274px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
    background: #ABD693;
    position: relative;
    z-index: 9;
}

#footer .footer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 0;
}

#footer .footer .image_footer {
    max-width: 468px;
}

#footer .footer .right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

#footer .footer .right ul.links {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0 10px;
}

#footer .footer .right ul.links li {
    width: 90px;
    color: var(--ff);
    text-transform: uppercase;
    cursor: pointer;
}

#footer .footer .right ul.links li a.active {
    color: var(--bg);
}

#footer .footer .right a.logo {
    width: 141px;
    cursor: pointer;
}

#footer .footer .right .social_media {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

#footer .footer .right .social_media a {
    font-size: 25px;
    color: var(--ff);
}

#footer .footer .right a.logo:hover,
#footer .footer .right ul.links li:hover,
#footer .footer .right .social_media a:hover {
    opacity: 0.7;
}

#footer .MdArrowBackIos {
    position: absolute;
    top: 0;
    right: 1%;
    transform: rotate(90deg) translateX(-50%);
    background: #7B498B;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ff);
    width: 36px;
    height: 36px;
    padding: 5px;
    padding-left: 10px;
    line-height: 0;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width:768px) {
    #footer .footer .image_footer {
        display: none;
    }
}