div#services {
    margin: 80px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 535px;
    padding-top: 70px;
    padding-bottom: 150px;
    margin-bottom: 0;
}

div#services img.img_services {
    position: absolute;
    inset: 0;
    z-index: -1;
    height: 100%;
    object-fit: cover;
}

div#services .services {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
}

div#services .services .title p {
    font-size: 45px;
    font-weight: 700;
    line-height: 64px;
    color: var(--bg);
}

div#services .services .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 255px;
}

div#services .services .title img {
    max-width: 196px;
}

#services .services .title img.border_services_2 {
    transform: rotate(180deg);
}

div#services .services p.p_1 {
    font-size: 19px;
    font-weight: 400;
    line-height: 34px;
    color: #1E1E1E;
    width: 90%;
    max-width: 848px;
}

.services_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    position: relative;
    top: -115px;
}

.services_data .data {
    display: flex;
    width: 100%;
    max-width: 850px;
    margin: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
}

.services_data .data .div_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 196px;
    gap: 30px;
}

.services_data .data .div_data .img {
    height: 230px;
    border-radius: 110.5px;
    overflow: hidden;
    border: 6px solid #ABD693;
}

.services_data .data .div_data .img img {
    height: 230px;
    object-fit: cover;
}

.services_data .data .div_data p.p_1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    color: #7B498B;
}

.services_data .data .div_data p.p_2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: #1E1E1E;
}

.services_data .button_a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
}

.services_data .button_a .button {
    width: 196px;
    height: 62px;
    border-radius: 500px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    background: #ABD693;
    color: var(--ff);
    padding: 0 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.services_data .button_a a {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    color: #636362;
    text-decoration: underline;
    cursor: pointer;
}


.services_data .button_a button:hover,
.services_data .button_a a:hover {
    opacity: 0.7;
}

@media (max-width:768px) {
    .services_data .data {
        justify-content: center;
    }
}